import { useTranslation } from "react-i18next";
import styles from "./banner.css";
import classNames from "classnames";

export const links = () => [{ rel: "stylesheet", href: styles }];

interface Props {
  url?: string | null;
  internal?: boolean;
}

export const Banner = ({ url, internal = false }: Props) => {
  const { t } = useTranslation("common");

  if (!url) {
    return null;
  }

  const altText = t("labels.banner");

  const containerClasses = classNames({
    "banner-container": true,
    "banner-container--internal": internal
  });

  return (
    <div className={containerClasses}>
      <img src={url} alt={altText} className="banner"></img>
    </div>);

};