import type { BoardConfiguration } from "~/types/jben/board_configuration";

const BASE_LINK = "https://fonts.googleapis.com/css2?";
const STYLES = ":ital,wght@0,400;0,500;0,700;1,400;1,500;1,700";
const STANDARD_FONTS = [
  "Arial",
  "Courier New",
  "Garamond",
  "Georgia",
  "Helvetica",
  "Palatino",
  "Times",
  "Times New Roman",
  "Untitled Sans",
  "Verdana",
  "untitled_sans",
  "arial",
  "helvetica",
  "times_new_roman",
  "verdana",
];

export function buildGoogleFontsLink(config: BoardConfiguration | null) {
  const googleFonts = [config?.primary_font, config?.secondary_font];

  const googleFontsLinks = googleFonts
    .filter((font) => !!font)
    .filter((font) => !STANDARD_FONTS.includes(font as string))
    .map((font) => `family=${font?.replace(/ /g, "+")}${STYLES}`)
    .join("&");

  if (googleFontsLinks.length === 0) {
    return null;
  }

  return `${BASE_LINK}${googleFontsLinks}`;
}
